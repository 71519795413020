@import "../../../globalStyles/scss/variables.scss";

.home-page {
  margin-bottom: 100px;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $small-screen) {
      flex-direction: column;
    }
  }
}

.title {
  padding-top: 100px;
  padding-bottom: 10px;
}

.hero {
  background-color: $background-light-color;
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;
  padding: 4rem 0;
  margin-bottom: 0px;

  &-text {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-right: 100px;
  }

  &-image {
    img {
      width: 500px;
      height: 333px;
      border-radius: 10px;
      opacity: 0.9;
      @media (max-width: $small-screen) {
        width: 100%;
        height: auto;
      }
    }
    @media (max-width: $small-screen) {
      margin-top: 30px;
    }
  }

  @media (max-width: $small-screen) {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: space-between;
    padding: 3rem 0;

    &-text {
      margin-top: 0;
      width: 100%;
    }

    &-action {
      margin-bottom: $spacer * 3;
      width: 100%;
    }
  }

  &__title {
    //background-color: $white;
    display: inline-block;
    //transform: skew(45deg);
    margin-bottom: 25px;

    h1 {
      display: inline-block;
      //transform: skew(-45deg);
      font-weight: $bold-font-weight;
      text-transform: uppercase;
      color: $base-header-color;
      font-size: $h2-font-size;
      font-weight: $bold-font-weight;
      max-width: 600px;
    }
  }

  &__description {
    max-width: 500px;
    p {
      color: $base-font-color;
      font-size: 18px;

      @media (max-width: $small-screen) {
        font-size: 16px;
      }
    }
  }
}