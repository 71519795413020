@import "../../../globalStyles/scss/variables.scss";




#header {
  align-items: center;
  align-items: stretch;
  justify-content: space-between;
  background-color: $white;
  border-bottom: 1px solid $base-border-color;
  display: flex;
  flex-direction: row;
  height: 4.55rem;
  position: relative;

  @media (max-width: $small-screen) {
    height: 3.55rem;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
}

.main-menu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.logo {
  margin-right: 10px;
}

.store-name {
  font-size: $h3-font-size;
  font-weight: $bold-font-weight;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  @media (max-width: $small-screen) {
    //position: absolute;
    //width: 100%;
    //height: 100%;
    //display: flex;
    //justify-content: center;
    //align-items: center;
  }
}

.left {
  display: flex;
  align-items: center;
  z-index: 1;

  ul {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.right {
  z-index: 1;
  display: flex;
  align-items: center;

  ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}


.nav-menu {
  display: flex;
  align-items: center;
  margin-right: 20px;

  @media (max-width: $small-screen) {
    display: none !important;
  }
  
  &__item {
    height: 100%;
    padding-left: 8px;
    padding-right: 8px;
    margin-left: 8px;
    margin-right: 8px;
  }
}


.cart-icon {
  margin-left: 20px;
  @media (max-width: $small-screen) {
  }
}

.login-icon {
  @media (max-width: $small-screen) {
    //display: none;
  }
}

.menu-icon {
  @media (min-width: $small-screen) {
    display: none !important;
  }
}